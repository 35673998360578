import _ from 'lodash'
import { Rule, Action, Operation } from '@wix/forms-common/dist/src/rules/rule'
import { getFieldType, FIELD_TYPE, getFieldRawValue } from '../viewer-utils'

interface FieldsMap {
  [fieldId: string]: {
    sdk: WixCodeField
    registeredEvents: string[]
  }
}

/**
 *
 * @param operator - QL operator
 * @returns A Corvid event name based on the given operator
 */
const mapOperatorToEvent = (operator: string, field: WixCodeField) => {
  switch (operator) {
    case '$exists':
    case '$eq':
    case '$hasSome':
      switch (getFieldType(field)) {
        case FIELD_TYPE.TEXT_INPUT:
          return 'onInput'
        default:
          return 'onChange'
      }
    default:
      return
  }
}

/**
 *
 * @param operator - QL operator
 * @param value - Expected value based on selected predicate
 * @returns A predefined predicate with the given expected value to be check against
 */
const mapOperatorToPredicate = (operator: string, value): ((field: WixCodeField) => boolean) => {
  switch (operator) {
    case '$exists':
      return (field: WixCodeField) => {
        const fieldValue = getFieldRawValue(field)

        switch (getFieldType(field)) {
          case FIELD_TYPE.CHECKBOX:
            return value ? fieldValue : !fieldValue
          default:
            return value ? !_.isEmpty(fieldValue) : _.isEmpty(fieldValue)
        }
      }
    case '$eq':
      return (field: WixCodeField) => getFieldRawValue(field) === value
    case '$hasSome':
      return (field: WixCodeField) => {
        const fieldValue = getFieldRawValue(field)

        switch (true) {
          case _.isArray(value):
            switch (true) {
              case _.isArray(fieldValue):
                return !_.isEmpty(_.intersection(value, fieldValue))
              default:
                return _.includes(value, fieldValue)
            }
          default:
            switch (true) {
              case _.isArray(fieldValue):
                return _.includes(fieldValue, value)
              default:
                return fieldValue === value
            }
        }
      }

    default:
      return () => false
  }
}

/**
 * @returns A map of fields and events to trigger the given rule
 * For example:
 * A response for a rule with condition on fieldId1 for input change & fieldId2 for input change OR out of focus
 * [
 *  {
 *    fieldId1: [onInput]
 *  },
 *  {
 *    fieldId2: [onInput, onBlur]
 *   }
 * ]
 */
const extractAffectedFields = (
  rule: Rule,
  fields: FieldsMap,
): { fieldId: string; events: string[] }[] => {
  if (!rule.conditions) {
    return []
  }

  // currently supports only a single condition as { "item": { "$operator": value }}
  const fieldId = _.head(_.keys(rule.conditions))

  if (!fields[fieldId]) {
    return []
  }

  const operator = _.head(_.keys(rule.conditions[fieldId]))
  const event = mapOperatorToEvent(operator, fields[fieldId].sdk)

  return [{ fieldId, events: [event] }]
}

const getCompIds = (action: Action) => {
  if (!action.compId) return []

  return _.isString(action.compId) ? [action.compId] : action.compId
}

const expand = (fields: FieldsMap) => {
  _.forEach(fields, (field) => {
    field.sdk.expand()
  })
}

const collapse = (fields: FieldsMap) => {
  _.forEach(fields, (field) => {
    field.sdk.collapse()
  })
}

const required = (fields: FieldsMap, value: boolean) => {
  _.forEach(fields, (field) => {
    field.sdk.required = value
  })
}

/**
 *
 * @returns A boolean value to indicate if the rule conditions valid or not
 */
const validateConditions = (rule: Rule, fields: FieldsMap): boolean => {
  const conditions = rule.conditions

  if (!conditions) return true

  // currently supports only a single condition as { "item": { "$operator": value }}
  const fieldId = _.head(_.keys(rule.conditions))

  if (!fields[fieldId]) {
    return false
  }

  const operator = _.head(_.keys(rule.conditions[fieldId]))
  const operatorValue = _.head(_.values(rule.conditions[fieldId]))

  return mapOperatorToPredicate(operator, operatorValue)(fields[fieldId].sdk)
}

/**
 *
 * @param positive - A flag to run the action or revert it in case the rule conditions isn't met anymore
 * @param ignoreFieldsIds - Which fields to ignore when executing the action
 * @returns An affected fieldsIds which was changed due to the given action
 */
const executeAction = (
  action: Action,
  fields: FieldsMap,
  { positive, ignoreFieldsIds }: { positive: boolean; ignoreFieldsIds: string[] },
): string[] => {
  const compIds = getCompIds(action)
  const filteredCompIds = _.difference(compIds, ignoreFieldsIds)
  const selectedFields = _.pick(fields, filteredCompIds)
  const actualAffectedFieldIds = _.keys(selectedFields)

  switch (action.operation) {
    case Operation.Show:
      ;(positive ? expand : collapse)(selectedFields)
      return actualAffectedFieldIds
    case Operation.Hide:
      ;(positive ? collapse : expand)(selectedFields)
      return actualAffectedFieldIds
    case Operation.Required:
      required(selectedFields, positive ? true : false)
      return actualAffectedFieldIds
    case Operation.Optional:
      required(selectedFields, positive ? false : true)
      return actualAffectedFieldIds
    default:
      return []
  }
}

/**
 *
 * @param positive - A flag to run the action or revert it in case the rule conditions isn't met anymore
 */
const executeActions = (
  rule: Rule,
  fields: FieldsMap,
  {
    positive = true,
    ignoreFieldsIds = [],
  }: { positive?: boolean; ignoreFieldsIds?: string[] } = {},
): string[] => {
  return _.chain(rule.actions)
    .map((action) => executeAction(action, fields, { positive, ignoreFieldsIds }))
    .flatten()
    .uniq()
    .value()
}

/**
 *
 * @description Scan given rules, execute only the first rule and skip all the other valid rules, for every rule with false condition revert the actions
 */
const runRules = (rules: Rule[], fields: FieldsMap): void => {
  let executed = false
  let affectedFieldsIds: string[] = []

  _.forEach(rules, (rule) => {
    if (!rule.enabled) {
      return
    }

    if (validateConditions(rule, fields)) {
      if (!executed) {
        executed = true
        const affected = executeActions(rule, fields)
        affectedFieldsIds = _.concat(affectedFieldsIds, affected)
      }
    } else {
      executeActions(rule, fields, { positive: false, ignoreFieldsIds: affectedFieldsIds })
    }
  })
}

/**
 *
 * @returns A 2 level map of fieldsIds & event names and which rules related to them, this useful to quickly register each event for every field
 * For example:
 * {
 *  fieldId1: {
 *    onInput: [rule1, rule2],
 *    onBlur: [rule3]
 *  },
 *  fieldId2: {
 *    onBlur: [rule1]
 *  }
 * }
 */
const mapRulesPerFieldAndEvent = (rules: Rule[], fields: FieldsMap) => {
  const rulesPerFieldAndEvent: { [fieldId: string]: { [event: string]: Rule[] } } = {}

  _.forEach(rules, (rule) => {
    if (!rule.enabled) {
      return
    }

    const affectedFields = extractAffectedFields(rule, fields)

    _.forEach(affectedFields, ({ fieldId, events }) => {
      if (!rulesPerFieldAndEvent[fieldId]) {
        rulesPerFieldAndEvent[fieldId] = {}
      }

      _.forEach(events, (event) => {
        if (!rulesPerFieldAndEvent[fieldId][event]) {
          rulesPerFieldAndEvent[fieldId][event] = []
        }

        rulesPerFieldAndEvent[fieldId][event].push(rule) // Currently this rules array not in use
      })
    })
  })

  return rulesPerFieldAndEvent
}

/**
 *
 * @returns A map of field uniqueId and Corvid field and placeholder for events that already registered for this field
 */
const mapFieldsPerUniqueId = (fields: WixCodeField[]): FieldsMap =>
  _.reduce(
    fields,
    (acc, field) => {
      acc[field.uniqueId] = { sdk: field, registeredEvents: [] }
      return acc
    },
    {},
  )

export const registerRulesIfExists = ({
  controllerSettings,
  fields,
}: {
  controllerSettings: ControllerSettings
  fields: WixCodeField[]
}) => {
  if (!controllerSettings.ok) {
    return
  }

  const rules: Rule[] = _.get(controllerSettings, 'data.rules', [])

  if (_.isEmpty(rules)) {
    return
  }

  const fieldsMap: FieldsMap = mapFieldsPerUniqueId(fields)
  const rulesPerFieldAndEvent = mapRulesPerFieldAndEvent(rules, fieldsMap)

  _.forEach(rulesPerFieldAndEvent, (events, fieldId) => {
    _.forEach(events, (r, event) => {
      const { sdk, registeredEvents } = fieldsMap[fieldId]

      if (sdk[event]) {
        if (!_.includes(registeredEvents, event)) {
          sdk[event](() => {
            runRules(rules, fieldsMap)
          })

          registeredEvents.push(event)
        }
      }
    })
  })

  runRules(rules, fieldsMap)
}
